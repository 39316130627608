import classNames from "classnames";
import React, { useState } from "react";
import { navigate } from "gatsby";
import { validation } from "../../utils/validation";
import { getLink, Image } from "../../utils/helper";

const Newsletter = ({ blok }) => {
  // const [email, setEmail] = useState();
  // const [message, setMessage] = useState();
  // const [isLoading, setIsLoading] = useState();

  const [error, setError] = useState(false);
  const [email, setEmail] = useState("");

  const validate = () => {
    return !validation.isEmpty(email) && validation.isValidEmail(email);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!validate()) {
      setError(true);
      return;
    }
    setError(false);
    if (window.renta) {
      const data = { email: email };
      blok?.section_footer?.forEach((i) => {
        i.hidden_fields?.forEach(({ key, value }) => {
          Object.assign(data, { [key]: value });
        });
      });
      window.renta.track("Subscribers", data);
      setEmail("");
      navigate(getLink(blok?.section_footer[0]?.submit_btn[0]?.Link?.cached_url));
    }
    return;
  };
  return (
    <div className="blog-footer-left">
      <div className="blog-footer-header-info">
        <span className="blogLeftTitle">{blok?.section_footer?.map((i) => i.Title)}</span>
        <p>{blok?.section_footer?.map((i) => i.Sub_Title)}</p>
      </div>
      <form className="input-form" onSubmit={handleSubmit}>
        <input
          aria-label="Email address"
          className={classNames(
            "appearance-none w-full sm:max-w-xs px-5 py-3 border border-gray-400 leading-snug rounded-md text-gray-900 bg-white placeholder-gray-600 focus:outline-none focus:shadow-outline focus:border-blue-300 transition duration-150 ease-in-out",
            error ? "error" : ""
          )}
          onChange={(event) => setEmail(event.target.value)}
          value={email ?? ""}
          placeholder={blok?.section_footer?.map((i) => i.Email_Input)}
          required
          type="email"
        />
        <div>
          <button type="submit">
            {blok?.section_footer?.map((i) => {
              return i?.submit_btn?.map((i) => i.Label);
            })}
          </button>
        </div>
        {blok?.section_footer?.map((i) => {
          return i.hidden_fields?.map(({ key, value, _uid }) => {
            return <input type="hidden" value={value} id={key} name={key} />;
          });
        })}
      </form>
    </div>
  );
};

export default Newsletter;
